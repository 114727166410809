.other h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 7px;
}

.other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

h1 {
  font-size: 24px;
  color: #333;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-container {
  margin: 0;
  width: auto;
  border-radius: 2px;
  /* max-width: var(--editor-max-width, 600px); */
  max-width: 100%;
  padding: 0px 4px;
  color: var(--editor-text-color, #000);
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.editor-container.readonly-container {
  margin: 0;
}
.editor-input.readonly-input {
  min-height: 0px;
}

.editor-inner {
  background: var(--editor-background, #fff);
  position: relative;
}
.editor-input.readonly-input > .editor-paragraph:first-child,
.editor-input.readonly-input > .editor-paragraph:last-child {
  white-space: initial !important;
  padding-inline-start: 0 !important;
}
.editor-input.readonly-input .editor-paragraph br:last-child {
  display: none;
}
.editor-input {
  min-height: var(--editor-min-height, 150px);
  max-height: var(--editor-input-max-height, none);
  overflow: var(--editor-input-overflow, visible);
  resize: none;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: var(--editor-input-padding, 15px 10px);
  caret-color: #444;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-text-code {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}

.editor-link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}

.tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  white-space: pre-wrap;
  margin: 1px auto 10px auto;
  max-height: 250px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
  line-height: 14px;
}

.editor-code {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  tab-size: 2;
  /* white-space: pre; */
  overflow-x: auto;
  position: relative;
}

.editor-code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}
.editor-code:after {
  content: attr(data-highlight-language);
  top: 0;
  right: 3px;
  padding: 3px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
  color: slategray;
}

.editor-tokenPunctuation {
  color: #999;
}

.editor-tokenProperty {
  color: #905;
}

.editor-tokenSelector {
  color: #690;
}

.editor-tokenOperator {
  color: #9a6e3a;
}

.editor-tokenAttr {
  color: #07a;
}

.editor-tokenVariable {
  color: #e90;
}

.editor-tokenFunction {
  color: #dd4a68;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-heading-h1 {
  font-size: 24px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
}

.editor-heading-h2 {
  font-size: 15px;
  color: rgb(101, 103, 107);
  font-weight: 700;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  text-transform: uppercase;
}

.editor-quote {
  margin: 0;
  margin-left: 20px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: decimal;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style: disc;
}

.editor-listitem {
  margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
  list-style-type: none;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: auto;
  display: flex;
}

.debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}

.debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}

.debug-timetravel-panel-button:hover {
  text-decoration: underline;
}

.debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}

.debug-timetravel-button:hover {
  text-decoration: underline;
}

.emoji {
  color: transparent;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}

.emoji-inner {
  padding: 0 0.15em;
}

.emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji.happysmile {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAACE1BMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD86isAAAB2bRQBAQD25CoaGAT15CqFfBdIQwwZFwTSwyTTxCStoR4KCQKGfRfz4in04yoMCwJHQgxRSw5STA7r2ijj0yeDehammhylmRwjIAYiHwbv3ikYFgR1bBQbGQV3bhRlXhGXjBpOSA355yrq2SjVxiTn1yewox776SvMvSM/OwtzahQODQJcVRACAgALCgKonB1FQAxEPwynmxxTTQ5GQQzs2yjQwSPOvyPNviNUTg62qR8kIQZPSQ3PwCO5rCB+dRW6rSDu3Sm3qh9YUg8lIgaBeBaUihmqnh2rnx3p2SiQhhmvoh4HBgHo2CgIBwHk1CeRhxlQSg6xpB6CeRZJRAySiBlXUQ9WUA/w3ynt3ChaVA+Vixl180CkAAAAU3RSTlMABfwYE47z9P75uJBWWWtt8e746+zt2djatrUWjcvIxxVs9Y8bjHcZZ/IaUHPQJ83OJHVPYPvcVdtuF1gUycoSTczxz2TzI3YEJiVodCgGTmVTUqncTiEAAALWSURBVFjD7ZfVWxtBFMUhJNkI2lIoBYoVLVaou7vvyQQIwZPgVJC2uLtDsbp7+ycWHtjsJuzsJF/7tQ85T7N3d377jdw7Z/z8fPLJp3+vgCCjISJMrw+LMBiPHfWWcvxILCQ6kaD2ArMnKRGoshS1l1SbzdUlr4psVQCnyfAQ45/CgVgGKnmRKoctBNwlf0842VnA1DjvpjkHcO4KO+eOFiuL/Jaan8GFvayc/Qfg+MHLqGkZqmtsnDSQBZ6icoIQFs5dFfnFU7VGVAyj2xeKIl5Bq9DeUOLczMJPXlHfkKu0C1Kw8l0Z9LQBeQr7mcMiz6AlaFOpoCSM8EyyI4bGUSeScTbQBOFoGRyCzzyjbAimgGIxsPmh+X5xgcks7iqN1GE3pY7pqoR8N21UIJMYJI1U9OuiZUFBsAjdije63RODXCKDCJQFGUWbumCj2wMxyCVSC40syICP0oE8dBuaM1KKXbKgg3jsnFpTgetkSyMl2CkL2oFHPLOeYLssSA8zO6gQ2/4+6I8N7bBoshVFm2wD2tlBtOU3KldZnmlDBsHGDhrCIaakVVJFmS5ePv1PYlj4sqNwiyXvEJr1iKMWNmf69/Z0v5Niarp7eoWHUWphU3NkTshRgrf2urHNx+r6D69BhHI0SS+1fho4hH92OvrW072htdlkam5tXG/2jXQKL2eRTD1FMrSYF43lZVfZpl3r73pf43wzjfQA+sGWh5km8by0WZ+1lJe3PLe2SQ7IRmQqWbVcLCuv/RRy/BlMxKoSZwHabGU7kq8ia3TOF6husRik2y7HkJvRgu48s/Wzj8lav69QpbGayPxQNCxtzZluxNnT7Lb2ag5gn3DHTM4CZy56ZNiva0FsdRWSfK8fJUjPvOyh90/VcEDZYG2p9YW58I219NPQ+ibnkgO8uI2oE3brxHcaXVzwKW8vSNGBmqjIcL0+PDIqJjDed+30yaf/QL8BILNaoYqaEPoAAAAASUVORK5CYII=');
}

.toolbar {
  overflow-x: auto;
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
}

.toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}

.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}

.toolbar button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}

.toolbar button.toolbar-item.active i {
  opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.toolbar .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.toolbar select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.toolbar select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.toolbar i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4eiIvPgo8L3N2Zz4=');
}

#block-controls button:hover {
  background-color: #efefef;
}

#block-controls button:focus-visible {
  border-color: blue;
}

#block-controls span.block-type {
  background-size: contain;
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
}

#block-controls span.block-type.paragraph {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktdGV4dC1wYXJhZ3JhcGgiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMiAxMi41YS41LjUgMCAwIDEgLjUtLjVoN2EuNS41IDAgMCAxIDAgMWgtN2EuNS41IDAgMCAxLS41LS41em0wLTNhLjUuNSAwIDAgMSAuNS0uNWgxMWEuNS41IDAgMCAxIDAgMWgtMTFhLjUuNSAwIDAgMS0uNS0uNXptMC0zYS41LjUgMCAwIDEgLjUtLjVoMTFhLjUuNSAwIDAgMSAwIDFoLTExYS41LjUgMCAwIDEtLjUtLjV6bTQtM2EuNS41IDAgMCAxIC41LS41aDdhLjUuNSAwIDAgMSAwIDFoLTdhLjUuNSAwIDAgMS0uNS0uNXoiLz4KPC9zdmc+');
}

.dropdown {
  z-index: 5;
  display: block;
  position: absolute;
  box-shadow:
    0 12px 28px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  min-width: 268px;
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.dropdown .item:first-child {
  margin-top: 8px;
}

.dropdown .item:last-child {
  margin-bottom: 8px;
}

.dropdown .item:hover {
  background-color: #eee;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  width: 200px;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
}

.link-editor {
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor div.link-edit {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktcGVuY2lsLWZpbGwiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZD0iTTEyLjg1NC4xNDZhLjUuNSAwIDAgMC0uNzA3IDBMMTAuNSAxLjc5MyAxNC4yMDcgNS41bDEuNjQ3LTEuNjQ2YS41LjUgMCAwIDAgMC0uNzA4bC0zLTN6bS42NDYgNi4wNjFMOS43OTMgMi41IDMuMjkzIDlIMy41YS41LjUgMCAwIDEgLjUuNXYuNWguNWEuNS41IDAgMCAxIC41LjV2LjVoLjVhLjUuNSAwIDAgMSAuNS41di41aC41YS41LjUgMCAwIDEgLjUuNXYuMjA3bDYuNS02LjV6bS03LjQ2OCA3LjQ2OEEuNS41IDAgMCAxIDYgMTMuNVYxM2gtLjVhLjUuNSAwIDAgMS0uNS0uNVYxMmgtLjVhLjUuNSAwIDAgMS0uNS0uNVYxMWgtLjVhLjUuNSAwIDAgMS0uNS0uNVYxMGgtLjVhLjQ5OS40OTkgMCAwIDEtLjE3NS0uMDMybC0uMTc5LjE3OGEuNS41IDAgMCAwLS4xMS4xNjhsLTIgNWEuNS41IDAgMCAwIC42NS42NWw1LTJhLjUuNSAwIDAgMCAuMTY4LS4xMWwuMTc4LS4xNzh6Ii8+Cjwvc3ZnPg==');
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

i.undo {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktYXJyb3ctY291bnRlcmNsb2Nrd2lzZSIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDNhNSA1IDAgMSAxLTQuNTQ2IDIuOTE0LjUuNSAwIDAgMC0uOTA4LS40MTdBNiA2IDAgMSAwIDggMnYxeiIvPgogIDxwYXRoIGQ9Ik04IDQuNDY2Vi41MzRhLjI1LjI1IDAgMCAwLS40MS0uMTkyTDUuMjMgMi4zMDhhLjI1LjI1IDAgMCAwIDAgLjM4NGwyLjM2IDEuOTY2QS4yNS4yNSAwIDAgMCA4IDQuNDY2eiIvPgo8L3N2Zz4=');
}

i.redo {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktYXJyb3ctY2xvY2t3aXNlIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTggM2E1IDUgMCAxIDAgNC41NDYgMi45MTQuNS41IDAgMCAxIC45MDgtLjQxN0E2IDYgMCAxIDEgOCAydjF6Ii8+CiAgPHBhdGggZD0iTTggNC40NjZWLjUzNGEuMjUuMjUgMCAwIDEgLjQxLS4xOTJsMi4zNiAxLjk2NmMuMTIuMS4xMi4yODQgMCAuMzg0TDguNDEgNC42NThBLjI1LjI1IDAgMCAxIDggNC40NjZ6Ii8+Cjwvc3ZnPg==');
}

.icon.paragraph {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktdGV4dC1wYXJhZ3JhcGgiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMiAxMi41YS41LjUgMCAwIDEgLjUtLjVoN2EuNS41IDAgMCAxIDAgMWgtN2EuNS41IDAgMCAxLS41LS41em0wLTNhLjUuNSAwIDAgMSAuNS0uNWgxMWEuNS41IDAgMCAxIDAgMWgtMTFhLjUuNSAwIDAgMS0uNS0uNXptMC0zYS41LjUgMCAwIDEgLjUtLjVoMTFhLjUuNSAwIDAgMSAwIDFoLTExYS41LjUgMCAwIDEtLjUtLjV6bTQtM2EuNS41IDAgMCAxIC41LS41aDdhLjUuNSAwIDAgMSAwIDFoLTdhLjUuNSAwIDAgMS0uNS0uNXoiLz4KPC9zdmc+');
}

.icon.large-heading,
#block-controls span.block-type.h1,
.icon.h1 {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktdHlwZS1oMSIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNOC42MzcgMTNWMy42NjlINy4zNzlWNy42MkgyLjc1OFYzLjY3SDEuNVYxM2gxLjI1OFY4LjcyOGg0LjYyVjEzaDEuMjU5em01LjMyOSAwVjMuNjY5aC0xLjI0NEwxMC41IDUuMzE2djEuMjY1bDIuMTYtMS41NjVoLjA2MlYxM2gxLjI0NHoiLz4KPC9zdmc+');
}

.icon.small-heading,
#block-controls span.block-type.h2,
.icon.h2 {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktdHlwZS1oMiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNNy42MzggMTNWMy42NjlINi4zOFY3LjYySDEuNzU5VjMuNjdILjVWMTNoMS4yNThWOC43MjhoNC42MlYxM2gxLjI1OXptMy4wMjItNi43MzN2LS4wNDhjMC0uODg5LjYzLTEuNjY4IDEuNzE2LTEuNjY4Ljk1NyAwIDEuNjc1LjYwOCAxLjY3NSAxLjU3MiAwIC44NTUtLjU1NCAxLjUwNC0xLjA2NyAyLjA4NWwtMy41MTMgMy45OTlWMTNIMTUuNXYtMS4wOTRoLTQuMjQ1di0uMDc1bDIuNDgxLTIuODQ0Yy44NzUtLjk5OCAxLjU4Ni0xLjc4NCAxLjU4Ni0yLjk1MyAwLTEuNDYzLTEuMTU1LTIuNTU2LTIuOTE5LTIuNTU2LTEuOTQxIDAtMi45NjYgMS4zMjYtMi45NjYgMi43NHYuMDQ5aDEuMjIzeiIvPgo8L3N2Zz4=');
}

.icon.bullet-list,
#block-controls span.block-type.ul,
.icon.ul {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktbGlzdC11bCIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01IDExLjVhLjUuNSAwIDAgMSAuNS0uNWg5YS41LjUgMCAwIDEgMCAxaC05YS41LjUgMCAwIDEtLjUtLjV6bTAtNGEuNS41IDAgMCAxIC41LS41aDlhLjUuNSAwIDAgMSAwIDFoLTlhLjUuNSAwIDAgMS0uNS0uNXptMC00YS41LjUgMCAwIDEgLjUtLjVoOWEuNS41IDAgMCAxIDAgMWgtOWEuNS41IDAgMCAxLS41LS41em0tMyAxYTEgMSAwIDEgMCAwLTIgMSAxIDAgMCAwIDAgMnptMCA0YTEgMSAwIDEgMCAwLTIgMSAxIDAgMCAwIDAgMnptMCA0YTEgMSAwIDEgMCAwLTIgMSAxIDAgMCAwIDAgMnoiLz4KPC9zdmc+');
}

.icon.numbered-list,
.icon.ol,
#block-controls span.block-type.ol {
  background-image: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9ImN1cnJlbnRDb2xvciIgY2xhc3M9ImJpIGJpLWxpc3Qtb2wiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNSAxMS41YS41LjUgMCAwIDEgLjUtLjVoOWEuNS41IDAgMCAxIDAgMWgtOWEuNS41IDAgMCAxLS41LS41em0wLTRhLjUuNSAwIDAgMSAuNS0uNWg5YS41LjUgMCAwIDEgMCAxaC05YS41LjUgMCAwIDEtLjUtLjV6bTAtNGEuNS41IDAgMCAxIC41LS41aDlhLjUuNSAwIDAgMSAwIDFoLTlhLjUuNSAwIDAgMS0uNS0uNXoiLz4KICA8cGF0aCBkPSJNMS43MTMgMTEuODY1di0uNDc0SDJjLjIxNyAwIC4zNjMtLjEzNy4zNjMtLjMxNyAwLS4xODUtLjE1OC0uMzEtLjM2MS0uMzEtLjIyMyAwLS4zNjcuMTUyLS4zNzMuMzFoLS41OWMuMDE2LS40NjcuMzczLS43ODcuOTg2LS43ODcuNTg4LS4wMDIuOTU0LjI5MS45NTcuNzAzYS41OTUuNTk1IDAgMCAxLS40OTIuNTk0di4wMzNhLjYxNS42MTUgMCAwIDEgLjU2OS42MzFjLjAwMy41MzMtLjUwMi44LTEuMDUxLjgtLjY1NiAwLTEtLjM3LTEuMDA4LS43OTRoLjU4MmMuMDA4LjE3OC4xODYuMzA2LjQyMi4zMDkuMjU0IDAgLjQyNC0uMTQ1LjQyMi0uMzUtLjAwMi0uMTk1LS4xNTUtLjM0OC0uNDE0LS4zNDhoLS4zem0tLjAwNC00LjY5OWgtLjYwNHYtLjAzNWMwLS40MDguMjk1LS44NDQuOTU4LS44NDQuNTgzIDAgLjk2LjMyNi45Ni43NTYgMCAuMzg5LS4yNTcuNjE3LS40NzYuODQ4bC0uNTM3LjU3MnYuMDNoMS4wNTRWOUgxLjE0M3YtLjM5NWwuOTU3LS45OWMuMTM4LS4xNDIuMjkzLS4zMDQuMjkzLS41MDggMC0uMTgtLjE0Ny0uMzItLjM0Mi0uMzJhLjMzLjMzIDAgMCAwLS4zNDIuMzM4di4wNDF6TTIuNTY0IDVoLS42MzVWMi45MjRoLS4wMzFsLS41OTguNDJ2LS41NjdsLjYyOS0uNDQzaC42MzVWNXoiLz4KPC9zdmc+');
}

.icon.quote,
#block-controls span.block-type.quote {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hhdC1zcXVhcmUtcXVvdGUiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZD0iTTE0IDFhMSAxIDAgMCAxIDEgMXY4YTEgMSAwIDAgMS0xIDFoLTIuNWEyIDIgMCAwIDAtMS42LjhMOCAxNC4zMzMgNi4xIDExLjhhMiAyIDAgMCAwLTEuNi0uOEgyYTEgMSAwIDAgMS0xLTFWMmExIDEgMCAwIDEgMS0xaDEyek0yIDBhMiAyIDAgMCAwLTIgMnY4YTIgMiAwIDAgMCAyIDJoMi41YTEgMSAwIDAgMSAuOC40bDEuOSAyLjUzM2ExIDEgMCAwIDAgMS42IDBsMS45LTIuNTMzYTEgMSAwIDAgMSAuOC0uNEgxNGEyIDIgMCAwIDAgMi0yVjJhMiAyIDAgMCAwLTItMkgyeiIvPgogIDxwYXRoIGQ9Ik03LjA2NiA0Ljc2QTEuNjY1IDEuNjY1IDAgMCAwIDQgNS42NjhhMS42NjcgMS42NjcgMCAwIDAgMi41NjEgMS40MDZjLS4xMzEuMzg5LS4zNzUuODA0LS43NzcgMS4yMmEuNDE3LjQxNyAwIDEgMCAuNi41OGMxLjQ4Ni0xLjU0IDEuMjkzLTMuMjE0LjY4Mi00LjExMnptNCAwQTEuNjY1IDEuNjY1IDAgMCAwIDggNS42NjhhMS42NjcgMS42NjcgMCAwIDAgMi41NjEgMS40MDZjLS4xMzEuMzg5LS4zNzUuODA0LS43NzcgMS4yMmEuNDE3LjQxNyAwIDEgMCAuNi41OGMxLjQ4Ni0xLjU0IDEuMjkzLTMuMjE0LjY4Mi00LjExMnoiLz4KPC9zdmc+');
}

.icon.code,
#block-controls span.block-type.code {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY29kZSIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNNS44NTQgNC44NTRhLjUuNSAwIDEgMC0uNzA4LS43MDhsLTMuNSAzLjVhLjUuNSAwIDAgMCAwIC43MDhsMy41IDMuNWEuNS41IDAgMCAwIC43MDgtLjcwOEwyLjcwNyA4bDMuMTQ3LTMuMTQ2em00LjI5MiAwYS41LjUgMCAwIDEgLjcwOC0uNzA4bDMuNSAzLjVhLjUuNSAwIDAgMSAwIC43MDhsLTMuNSAzLjVhLjUuNSAwIDAgMS0uNzA4LS43MDhMMTMuMjkzIDhsLTMuMTQ3LTMuMTQ2eiIvPgo8L3N2Zz4=');
}

i.bold {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktdHlwZS1ib2xkIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik04LjIxIDEzYzIuMTA2IDAgMy40MTItMS4wODcgMy40MTItMi44MjMgMC0xLjMwNi0uOTg0LTIuMjgzLTIuMzI0LTIuMzg2di0uMDU1YTIuMTc2IDIuMTc2IDAgMCAwIDEuODUyLTIuMTRjMC0xLjUxLTEuMTYyLTIuNDYtMy4wMTQtMi40NkgzLjg0M1YxM0g4LjIxek01LjkwOCA0LjY3NGgxLjY5NmMuOTYzIDAgMS41MTcuNDUxIDEuNTE3IDEuMjQ0IDAgLjgzNC0uNjI5IDEuMzItMS43MyAxLjMySDUuOTA4VjQuNjczem0wIDYuNzg4VjguNTk4aDEuNzNjMS4yMTcgMCAxLjg4LjQ5MiAxLjg4IDEuNDE1IDAgLjk0My0uNjQzIDEuNDQ5LTEuODMyIDEuNDQ5SDUuOTA3eiIvPgo8L3N2Zz4=');
}

i.italic {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktdHlwZS1pdGFsaWMiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZD0iTTcuOTkxIDExLjY3NCA5LjUzIDQuNDU1Yy4xMjMtLjU5NS4yNDYtLjcxIDEuMzQ3LS44MDdsLjExLS41Mkg3LjIxMWwtLjExLjUyYzEuMDYuMDk2IDEuMTI4LjIxMiAxLjAwNS44MDdMNi41NyAxMS42NzRjLS4xMjMuNTk1LS4yNDYuNzEtMS4zNDYuODA2bC0uMTEuNTJoMy43NzRsLjExLS41MmMtMS4wNi0uMDk1LTEuMTI5LS4yMTEtMS4wMDYtLjgwNnoiLz4KPC9zdmc+');
}

i.underline {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktdHlwZS11bmRlcmxpbmUiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZD0iTTUuMzEzIDMuMTM2aC0xLjIzVjkuNTRjMCAyLjEwNSAxLjQ3IDMuNjIzIDMuOTE3IDMuNjIzczMuOTE3LTEuNTE4IDMuOTE3LTMuNjIzVjMuMTM2aC0xLjIzdjYuMzIzYzAgMS40OS0uOTc4IDIuNTctMi42ODcgMi41Ny0xLjcwOSAwLTIuNjg3LTEuMDgtMi42ODctMi41N1YzLjEzNnpNMTIuNSAxNWgtOXYtMWg5djF6Ii8+Cjwvc3ZnPg==');
}

i.strikethrough {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktdHlwZS1zdHJpa2V0aHJvdWdoIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik02LjMzMyA1LjY4NmMwIC4zMS4wODMuNTgxLjI3LjgxNEg1LjE2NmEyLjc3NiAyLjc3NiAwIDAgMS0uMDk5LS43NmMwLTEuNjI3IDEuNDM2LTIuNzY4IDMuNDgtMi43NjggMS45NjkgMCAzLjM5IDEuMTc1IDMuNDQ1IDIuODVoLTEuMjNjLS4xMS0xLjA4LS45NjQtMS43NDMtMi4yNS0xLjc0My0xLjIzIDAtMi4xOC42MDItMi4xOCAxLjYwN3ptMi4xOTQgNy40NzhjLTIuMTUzIDAtMy41ODktMS4xMDctMy43MDUtMi44MWgxLjIzYy4xNDQgMS4wNiAxLjEyOSAxLjcwMyAyLjU0NCAxLjcwMyAxLjM0IDAgMi4zMS0uNzA1IDIuMzEtMS42NzUgMC0uODI3LS41NDctMS4zNzQtMS45MTQtMS42NzVMOC4wNDYgOC41SDF2LTFoMTR2MWgtMy41MDRjLjQ2OC40MzcuNjc1Ljk5NC42NzUgMS42OTcgMCAxLjgyNi0xLjQzNiAyLjk2Ny0zLjY0NCAyLjk2N3oiLz4KPC9zdmc+');
}

i.code {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY29kZSIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNNS44NTQgNC44NTRhLjUuNSAwIDEgMC0uNzA4LS43MDhsLTMuNSAzLjVhLjUuNSAwIDAgMCAwIC43MDhsMy41IDMuNWEuNS41IDAgMCAwIC43MDgtLjcwOEwyLjcwNyA4bDMuMTQ3LTMuMTQ2em00LjI5MiAwYS41LjUgMCAwIDEgLjcwOC0uNzA4bDMuNSAzLjVhLjUuNSAwIDAgMSAwIC43MDhsLTMuNSAzLjVhLjUuNSAwIDAgMS0uNzA4LS43MDhMMTMuMjkzIDhsLTMuMTQ3LTMuMTQ2eiIvPgo8L3N2Zz4=');
}

i.link {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktbGluayIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNNi4zNTQgNS41SDRhMyAzIDAgMCAwIDAgNmgzYTMgMyAwIDAgMCAyLjgzLTRIOWMtLjA4NiAwLS4xNy4wMS0uMjUuMDMxQTIgMiAwIDAgMSA3IDEwLjVINGEyIDIgMCAxIDEgMC00aDEuNTM1Yy4yMTgtLjM3Ni40OTUtLjcxNC44Mi0xeiIvPgogIDxwYXRoIGQ9Ik05IDUuNWEzIDMgMCAwIDAtMi44MyA0aDEuMDk4QTIgMiAwIDAgMSA5IDYuNWgzYTIgMiAwIDEgMSAwIDRoLTEuNTM1YTQuMDIgNC4wMiAwIDAgMS0uODIgMUgxMmEzIDMgMCAxIDAgMC02SDl6Ii8+Cjwvc3ZnPg==');
}

i.left-align {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktdGV4dC1sZWZ0IiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIgMTIuNWEuNS41IDAgMCAxIC41LS41aDdhLjUuNSAwIDAgMSAwIDFoLTdhLjUuNSAwIDAgMS0uNS0uNXptMC0zYS41LjUgMCAwIDEgLjUtLjVoMTFhLjUuNSAwIDAgMSAwIDFoLTExYS41LjUgMCAwIDEtLjUtLjV6bTAtM2EuNS41IDAgMCAxIC41LS41aDdhLjUuNSAwIDAgMSAwIDFoLTdhLjUuNSAwIDAgMS0uNS0uNXptMC0zYS41LjUgMCAwIDEgLjUtLjVoMTFhLjUuNSAwIDAgMSAwIDFoLTExYS41LjUgMCAwIDEtLjUtLjV6Ii8+Cjwvc3ZnPg==');
}

i.center-align {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktdGV4dC1jZW50ZXIiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNCAxMi41YS41LjUgMCAwIDEgLjUtLjVoN2EuNS41IDAgMCAxIDAgMWgtN2EuNS41IDAgMCAxLS41LS41em0tMi0zYS41LjUgMCAwIDEgLjUtLjVoMTFhLjUuNSAwIDAgMSAwIDFoLTExYS41LjUgMCAwIDEtLjUtLjV6bTItM2EuNS41IDAgMCAxIC41LS41aDdhLjUuNSAwIDAgMSAwIDFoLTdhLjUuNSAwIDAgMS0uNS0uNXptLTItM2EuNS41IDAgMCAxIC41LS41aDExYS41LjUgMCAwIDEgMCAxaC0xMWEuNS41IDAgMCAxLS41LS41eiIvPgo8L3N2Zz4=');
}

i.right-align {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktdGV4dC1yaWdodCIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02IDEyLjVhLjUuNSAwIDAgMSAuNS0uNWg3YS41LjUgMCAwIDEgMCAxaC03YS41LjUgMCAwIDEtLjUtLjV6bS00LTNhLjUuNSAwIDAgMSAuNS0uNWgxMWEuNS41IDAgMCAxIDAgMWgtMTFhLjUuNSAwIDAgMS0uNS0uNXptNC0zYS41LjUgMCAwIDEgLjUtLjVoN2EuNS41IDAgMCAxIDAgMWgtN2EuNS41IDAgMCAxLS41LS41em0tNC0zYS41LjUgMCAwIDEgLjUtLjVoMTFhLjUuNSAwIDAgMSAwIDFoLTExYS41LjUgMCAwIDEtLjUtLjV6Ii8+Cjwvc3ZnPg==');
}

i.justify-align {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktanVzdGlmeSIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyLjVhLjUuNSAwIDAgMSAuNS0uNWgxMWEuNS41IDAgMCAxIDAgMWgtMTFhLjUuNSAwIDAgMS0uNS0uNXptMC0zYS41LjUgMCAwIDEgLjUtLjVoMTFhLjUuNSAwIDAgMSAwIDFoLTExYS41LjUgMCAwIDEtLjUtLjV6bTAtM2EuNS41IDAgMCAxIC41LS41aDExYS41LjUgMCAwIDEgMCAxaC0xMWEuNS41IDAgMCAxLS41LS41em0wLTNhLjUuNSAwIDAgMSAuNS0uNWgxMWEuNS41IDAgMCAxIDAgMWgtMTFhLjUuNSAwIDAgMS0uNS0uNXoiLz4KPC9zdmc+');
}

#mentions-typeahead {
  position: fixed;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 3;
}

#mentions-typeahead ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
}

#mentions-typeahead ul li {
  padding: 10px 15px;
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

#mentions-typeahead ul li.selected {
  background: #eee;
}
