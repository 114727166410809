.task-table-scroll {
  position: relative;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: 550px;
}
.task-table-scroll table {
  /* width: 100%;
  min-width: 1280px;
  margin: auto; */
}
.table-wrap {
  position: relative;
}
.task-table-scroll th,
.task-table-scroll td {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 0px;
  padding-right: 0px;
  vertical-align: top;
  background-color: #fff;
  color: #000;
  font-weight: 400;
  font-size: 14px;
}
.task-table-scroll td {
  border-right: 0.5px solid #dddddd;
  border-left: 0.5px solid #dddddd;
}
.task-table-scroll tr:last-child {
  border-bottom: 1px solid #dddddd;
}
.task-table-scroll th {
  border-bottom: 1px solid #dddddd;
}
.task-table-scroll thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  /* padding-right: 67px; */
}

th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  /* padding-right: 230px !important; */
}
thead th:first-child {
  z-index: 5;
}

.ms-ProgressIndicator-itemProgress {
  padding: 0px 0px;
}
