.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: white;
  border: none;
  border-bottom: 1px solid gray;
  border-radius: 0;
}
